@use "styles/colours";
@use "styles/typography";
@use "styles/breakpoints";
@use "styles/vars";

html {
  scroll-behavior: smooth;
}

.menu {
  @include vars.mainBackground;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    position: sticky;
    top: 0;
    z-index: 401; // 1 level above nutritional disclaimer
  }

  &__wrapper {
    overflow: hidden;
  }

  &__container {
    display: flex;
    flex-direction: column;

    :last-child {
      .menu__section-wrapper {
        margin-bottom: 0;
      }
    }

    @media (min-width: breakpoints.$md) {
      .menu__section-group:last-of-type {
        .menu__list-item {
          margin-bottom: 0;
        }
      }
    }
  }

  &__section {
    &-heading {
      @extend .title-three;
      margin-bottom: 12px;

      @media (min-width: breakpoints.$md) {
        font-family: typography.$sini-bold;
        margin-bottom: 16px;
        margin-top: 24px;
        font-size: 28px;
        letter-spacing: 0px;
        color: colours.$darkGrey;
        line-height: 32px;
        text-transform: none;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 24px; // this is 32px space -8px from item's marginBottom

      @media (min-width: breakpoints.$md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        margin-bottom: 20px; // this + 20 from bottom of item = 40
      }
    }

    &-group {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;

      @media (min-width: breakpoints.$md) {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 8px;
    border-radius: 8px;
    width: 100%;

    @media (min-width: breakpoints.$md) {
      flex: 1;
      min-height: 320px;
      margin-right: 16px;
      margin-bottom: 20px;
    }

    &--placeholder {
      margin-right: 16px;
      margin-bottom: 0px;
      height: 0;
      opacity: 1;
      display: none;

      @media (min-width: breakpoints.$md) {
        display: flex;
        flex: 1;
        height: 320px;
      }
    }
  }

  &__product {
    display: flex;
    width: 100%;

    &--small {
      display: flex;
      flex: 1;
      width: 100%;
    }

    &-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      align-items: stretch;

      &--big {
        flex-direction: column;
        overflow: hidden;
        border-radius: 8px;

        .menu__product-heading {
          flex-direction: row;

          :nth-child(2) {
            margin-top: 0px;
          }
        }
      }

      &:active {
        background-color: colours.$lightestGrey;
      }

      @media (min-width: breakpoints.$md) {
        flex-direction: column;
        justify-content: flex-start;
      }

      &--small {
        justify-content: flex-start;
        height: 100%;
        align-items: stretch;

        &:active {
          background-color: colours.$lightestGrey;
        }

        @media (min-width: breakpoints.$md) {
          flex-direction: column;
          width: 100%;
          position: relative;
          display: flex;
          flex: 1;
        }
      }
    }

    &-imageWrapper {
      align-self: center;
      margin: 0 auto;
      position: relative;
    }

    &-image {
      height: 88px;
      width: 110px;
      object-fit: contain;

      &--big {
        min-width: 328px;
        height: 176px;
        object-fit: cover;
      }

      &--placeholder {
        object-fit: contain;
      }

      @media (min-width: breakpoints.$md) {
        height: 188px;
        width: 235px;

        &--small {
          height: 94px;
          width: 118px;
        }
      }
    }

    &-information {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: auto;
      padding: 24px 24px 16px 0;
      text-align: left;

      @media (min-width: breakpoints.$md) {
        padding: 0 24px 24px;
      }

      &--small {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0 16px 16px;
        text-align: left;
        @media (min-width: breakpoints.$md) {
          padding: 0 12px 16px 16px;
          display: flex;
          flex: 1;
          justify-content: space-between;
        }
      }
    }

    &-heading {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;

      > :not(:only-child):first-child {
        margin-top: -8px;
      }

      :nth-child(2) {
        margin-top: 4px;
      }

      @media (min-width: breakpoints.$md) {
        flex-direction: row;

        > :not(:only-child):first-child {
          margin-top: -16px;
          align-self: center;
        }

        :nth-child(2) {
          margin-top: 0;
        }
      }
    }

    &-title {
      display: flex;
      height: 100%;
      @extend .small-product-title;
      @media (min-width: breakpoints.$md) {
        margin-bottom: 16px;
      }

      &--small {
        @extend .small-product-title;
        display: flex;

        @media (min-width: breakpoints.$md) {
          margin-bottom: 8px;
        }
      }
    }

    &-contentWrapper {
      display: flex;
      flex-direction: column;
      padding: 16px;
      text-align: left;

      .menu__product-prompt {
        padding-top: 8px;
      }

      .menu__product-price {
        padding: 8px 0;

        @media (min-width: breakpoints.$md) {
          padding-top: 0;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      &--small {
        display: flex;
        flex-direction: column;
      }
    }

    &-ingridients {
      @extend .caption-one;
      margin-bottom: 8px;
      display: none;

      @media (min-width: breakpoints.$md) {
        @include vars.wrap-and-truncate;
        height: 100%;
      }
    }

    &-ingridients:empty::before {
      content: "\feff";
    }

    &-details {
      display: flex;
      padding-top: 4px;
      margin-bottom: 8px;
      @media (min-width: breakpoints.$md) {
        padding-top: 0px;
        padding-right: 0px;
      }
    }

    &-price {
      @extend .caption-one;
      padding-right: 16px;
    }

    &-prompt {
      display: flex;
      height: 100%;
      align-items: flex-end;
      @extend .title-four;
      font-size: 16px;
      letter-spacing: 0.5px;

      &--small {
        display: flex;
        align-items: flex-end;
        @extend .title-four;
        font-size: 16px;
        letter-spacing: 0.5px;
        font-weight: bold;
      }
    }
  }

  &__combined-item {
    // overrides product styles to keep mobile look
    .menu__product {
      &-container {
        flex-direction: row;
        justify-content: flex-start;
      }
      &-heading {
        @media (min-width: breakpoints.$md) {
          > :not(:only-child):first-child {
            margin-top: 0px;
            margin-bottom: 8px;
          }
        }
      }
      &-title {
        margin-bottom: 0;
      }
      &-image {
        height: 88px;
        width: 110px;
      }
      &-information {
        padding: 24px 24px 16px 0;
      }
      &-ingridients {
        display: none;
      }
      &-prompt {
        display: none;
      }
    }
  }

  &__combined-item-option {
    padding: 0px 16px;
  }

  &__size-and-nutrition {
    @extend .caption-one;
    @media (min-width: breakpoints.$md) {
      flex: 2;
    }
  }
}

.menuTab {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  scroll-behavior: smooth;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__btn {
    cursor: pointer;
    @extend .title-four;
    background-color: colours.$white;
    color: colours.$black;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;

    &--active {
      cursor: pointer;
      @extend .title-four;
      background-color: colours.$black;
      color: colours.$yellow;
      padding: 8px;
      border-radius: 8px;
      text-align: center;
      white-space: nowrap;
    }
  }
}

@mixin nutritionalBarStyle($opacity: 1, $position: 0%) {
  opacity: $opacity;
  transform: translateY($position);
}

.menu-nutrition-info {
  &__content {
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 24px;
    z-index: 400;
    background: -webkit-linear-gradient(#f0f0f0 70%, #f0f0f000 100%);

    &-transparent {
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: transparent;
      z-index: 400;
    }
  }

  &__testContent {
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 0;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 24px;
    z-index: 400;
    background: -webkit-linear-gradient(#f0f0f0 70%, #f0f0f000 100%);
  }

  &__text {
    text-align: center;
    @extend .caption-one;
  }
}

.surcharges {
  position: fixed;
  width: 100%;
  z-index: 400;
  overflow-y: hidden;

  &-container {
    position: sticky;
    width: 100%;
    z-index: 401;
    box-shadow: vars.$tripleBoxShadow;

    &:last-child {
      margin-bottom: 16px;
    }
  }
}
