@use "styles/colours";
@use "styles/typography";
@use "styles/breakpoints";
@use "styles/vars";

.upsell {
  flex: 1;

  &-background {
    flex: 1;
    @include vars.mainBackground;
  }

  &-container {
    padding-left: 16px;
    padding-right: 16px;
    max-width: 1151px;
    margin: auto;
    @media (min-width: breakpoints.$md) {
      padding-left: 24px;
      padding-right: 24px;
    }
    @media (min-width: breakpoints.$lg) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  .nutrition-info {
    padding-top: 8px;
    padding-bottom: 8px;
    @include vars.mainBackground;
  }

  .list-group {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    @media (min-width: breakpoints.$md) {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 112px;
    margin-bottom: 8px;
    border-radius: 8px;
    flex: 1;

    @media (min-width: breakpoints.$md) {
      flex: 1;
      min-height: 320px;
      margin-right: 16px;
      margin-bottom: 20px;
    }
  }

  .one-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 112px;
    margin-bottom: 8px;
    border-radius: 8px;
    max-width: 372px;
    margin: 0 auto;

    @media (min-width: breakpoints.$md) {
      max-width: 372px;
      min-height: 320px;
      margin-right: 16px;
      margin-bottom: 20px;
    }
  }

  .item-placeholder {
    margin-right: 16px;
    margin-bottom: 0px;
    height: 0;
    opacity: 1;
    @media (min-width: breakpoints.$md) {
      flex: 1;
      height: 320px;
    }
  }

  .primary-black-button {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 32px;
    max-width: 372px;
    margin: 0 auto
  }
}
