@use "styles/colours";
@use "styles/breakpoints";
@use "styles/typography";
@use "styles/vars";

@keyframes slide-in-top {
  0% {
    height: 0;
    overflow: hidden;
  }
  100% {
    overflow: auto;
    height: 100%;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.base-container__page .header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 500;
  box-shadow: vars.$tripleBoxShadow;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &--black {
    background-color: colours.$darkGrey;
    position: relative;
  }

  &--white {
    background-color: colours.$white;
  }

  &__top {
    color: colours.$white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    padding-top: 16px;
    min-height: 76px;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    padding-top: 16px;
    position: relative;

    > :nth-child(2) {
      margin-top: 16px;
    }

    &--scrolled {
      display: flex;
      overflow-x: auto;
      max-width: 100%;
    }
    &--static {
      width: 100%;
    }

    @media (min-width: breakpoints.$md) {
      align-items: center;
      justify-content: center;

      &--static {
        padding: 0 116px;
        box-sizing: border-box;
      }

      &--scrolled {
        justify-content: center;
      }
    }

    @media (min-width: breakpoints.$lg) {
      &--static {
        padding: 0 200px;
        width: -webkit-fill-available;
      }
    }
  }

  &__col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__logo {
    width: 57px;
    margin-right: 20px;
    z-index: 25;

    @media (min-width: breakpoints.$md) {
      position: absolute;
      top: 0;
      width: 110px;
    }
  }

  &__menu {
    position: relative;
    @extend .title-two;
    line-height: 32px;
    color: colours.$white;
    @include vars.linkUnderline(colours.$white);

    &--active {
      color: colours.$yellow;
      @include vars.linkUnderline(colours.$yellow, 50%);

      &::after {
        border-color: colours.$yellow;
        border-width: 1px;
        margin-left: 25%;
      }
    }

    @media (min-width: breakpoints.$md) {
      margin-left: 142px;
    }
  }

  &__account {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;

    &--open {
      .header-dropdown__wrapper {
        animation: slide-in-top 0.4s ease-in-out both;
      }
      .header__account-icon {
        svg {
          fill: colours.$yellow;
        }

        &::after {
          border-color: colours.$yellow;
          border-width: 1px;
          width: 70%;
          margin-left: 4px;
        }
      }
    }

    &-badge {
      margin-top: -23px;
      margin-left: -13px;

      @media (min-width: breakpoints.$md) {
        margin-left: -3px;
      }
    }

    &-icon {
      display: flex;
      position: relative;
      @include vars.linkUnderline;

      &:after {
        bottom: -4px;
      }

      svg {
        fill: colours.$white;
        transition: all 0.1s ease;
        * {
          transition: inherit;
        }
      }
    }

    &:hover {
      .header__greeting {
        color: colours.$yellow;
      }
      .header__account-icon {
        &::after {
          border-color: colours.$yellow;
          border-width: 1px;
          width: 70%;
          margin-left: 4px;
        }

        svg {
          fill: colours.$yellow;
        }
      }
    }

    @media (min-width: breakpoints.$md) {
      padding-left: 12px;

      &--open {
        .header-dropdown__wrapper {
          animation: fade-in 0.4s ease-in-out both;
        }
      }
    }
  }

  &__greeting {
    @extend .title-three;
    white-space: nowrap;
    color: colours.$white;
    margin-left: 4px;
    transition: color 0.3s ease 0.1s;
  }

  &__title {
    &-text {
      @extend .title-one;
      margin: 8px auto;
    }

    &-with-content-below {
      margin-bottom: 16px;
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      @media (min-width: breakpoints.$md) {
        width: 100%;
        max-width: breakpoints.$md;
      }
    }
  }

  &__barcode {
    position: absolute;

    @media (min-width: breakpoints.$md) {
      top: 24px;
      left: 166px;
    }

    @media (min-width: breakpoints.$lg) {
      left: 232px;
    }

    &-btn {
      display: flex;
      flex-direction: row;
    }

    &-icon {
      width: 30px;
      height: 30px;
      align-self: center;
    }

    &-text {
      @extend .title-three;
      color: colours.$black;
      align-self: center;
      margin-left: 12px;
    }
  }

  &__barcode-icon {
    width: 30px;
    height: 30px;
  }

  &__cart-button-wrapper {
    @media (min-width: breakpoints.$md) {
      margin-left: 32px;
    }
  }
}

.header-dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 76px);
  position: fixed;
  box-sizing: border-box;
  z-index: 10;
  top: 76px;
  left: 0;

  &--left {
    left: auto;
    right: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    color: colours.$white;
    height: 100%;
    padding: 0 16px;
    background-color: colours.$darkGrey;
    overflow: auto;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 1;
    background-color: colours.$darkGrey;

    [data-testid="icon-button"] {
      background-color: colours.$darkGrey;
      margin-right: 16px;

      img {
        filter: invert(50);
      }
    }
  }

  .header-dropdown__link {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  &__item {
    .gradient_line {
      filter: invert(1);
    }

    &:hover {
      .header-dropdown__link {
        color: colours.$yellow;
      }
    }
  }

  &__link {
    @extend .title-four;
    text-transform: none;
    display: block;
    color: colours.$white;
    text-decoration: none;
    width: 100%;
    padding: 22px 0;
    text-align: left;
  }

  @media (min-width: breakpoints.$md) {
    position: absolute;
    width: 280px;
    height: auto;
    top: 16px;
    min-height: auto;
    background-color: transparent;
    padding: 0;
    max-height: calc(100vh - 60px);

    &__wrapper {
      padding: 0 16px;
      background-color: colours.$darkGrey;
      border-radius: 4px;
      box-shadow: vars.$tripleBoxShadow;
      min-height: max-content !important;
    }

    &__close {
      display: none;
    }

    &__extra {
      height: 48px;
      min-height: 48px;
    }
  }
}
