@use "styles/colours";
@use "styles/typography";

.gomex-black-card {
  display: flex;
  flex: 1;
  width: 100%;
}

.gomex-header {
  &__text {
    @extend .title-three;
    color: colours.$white;
    flex: 1;
    margin-top: 1px;
  }

  &__animation {
    width: 116px;
    height: 50px;
    margin-right: -12px;
    margin-left: -20px;
    margin-top: -12px;
  }

  &__loading-animation {
    height: 32px;
    width: 64px;
    margin-top: -4px;
    margin-right: -16px;

    path {
      fill: colours.$white;
    }
  }

  &--text-black {
    color: black;
  }
}
