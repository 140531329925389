@use "styles/typography";
@use "styles/breakpoints";
@use "styles/vars";

.main-recent-orders {
  @include vars.mainBackground;
  flex-grow: 1;
  &__container {
    flex: 1;
  }

  &__order {
    width: 100%;
    padding: 4px 0;
  }
}
