@use "styles/colours";
@use "styles/breakpoints";
@use "styles/vars";
@use "styles/typography";

.middle-modal {
  position: fixed;
  width: 100%;
  top: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  max-height: 100vh;
  max-width: 100vw;
  height: 100vh;
  @include vars.overlay;
  overflow: hidden;

  &__container {
    position: absolute;
    background-color: colours.$white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100vh;
    max-width: 100vw;
    height: inherit;
    overflow: hidden;

    &--warning {
      height: auto;
      border-radius: 8px;
      overflow: auto;
    }

    @media (min-width: breakpoints.$md) {
      border-radius: 8px;
      height: auto;
    }

    &-transparent {
      background-color: transparent;
      overflow: auto;
    }

    &-small {
      transition: none !important;
      max-width: 374px;
      width: calc(100vw - 32px);
      height: auto;
      border-radius: 12px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 373px) {
        width: 100%;
      }
    }

    &-large {
      height: 100vh;
      max-width: 568px;
      width: 100%;
      overflow: auto;

      @media (min-width: 568px) {
        max-height: calc(100vh - 16px);
        height: auto;
        border-radius: 8px;
      }
    }
  }
}

.modal-with-button {
  justify-content: center;
  align-items: center;
  padding: 16px 16px 0px 16px;
  width: 328px;
  height: fit-content;
  border-radius: 8px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  @media (min-width: breakpoints.$md) {
    width: 374px;
    height: auto;
  }

  &__icon {
    width: 100%;
    height: 32px;

    &-img {
      float: right;
      width: 32px;
      height: 32px;
    }
  }

  &__image {
    margin-top: 16px;
    margin-bottom: 32px;
    width: 264px;
    height: 212px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  &--empty {
    margin-bottom: 16px;
  }

  &__title {
    @extend .large-title;
    text-align: center;
    margin-bottom: 16px;
  }

  &__message {
    @extend .body;
    text-align: center;

    &-bold {
      @extend .body-bold;
      text-align: center;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 16px;

    &--primary {
      flex: 0.5;
      margin-left: 8px;
    }

    &--secondary {
      flex: 0.5;
      margin-right: 8px;
    }
  }

  &__link {
    justify-content: center;
    align-self: center;
    display: flex;
    margin-top: 16px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 16px;

    &--primary {
      flex: 0.5;
      margin-left: 8px;
    }

    &--secondary {
      flex: 0.5;
      margin-right: 8px;
    }
  }

  &__link {
    justify-content: center;
    align-self: center;
    display: flex;
    margin-top: 16px;
  }
}

#modal-list-options {
  max-width: 568px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  @include vars.mainBackground;
  position: relative;

  @media (min-width: 568px) {
    width: 568px;
    min-height: auto;
    max-height: calc(100vh - 64px);
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.modal-mobile-verification {
  justify-content: center;
  align-items: center;
  padding: 16px 16px 0px 16px;
  width: 328px;
  height: fit-content;
  border-radius: 8px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  @media (min-width: breakpoints.$md) {
    width: 374px;
    height: auto;
  }

  &__image {
    margin-top: 16px;
    margin-bottom: 32px;
    width: 264px;
    height: 212px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  &__title {
    @extend .large-title;
    text-align: center;
    margin-bottom: 16px;
  }

  &__message {
    @extend .body;
    text-align: center;

    &-bold {
      @extend .body-bold;
      text-align: center;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 16px;

    &--primary {
      flex: 0.5;
      margin-left: 8px;
    }

    &--secondary {
      flex: 0.5;
      margin-right: 8px;
    }
  }

  &__input-number {
    margin-top: 24px;
  }
}