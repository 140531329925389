@use "styles/vars";

.order-status {
  flex-grow: 1;
  @include vars.mainBackground;
}

#uber-iframe {
  width: 100%;
  height: 800px;
}
