@use "styles/colours";
@use "styles/typography";
@use "styles/breakpoints";

.main-forgot {
  &__subtitle {
    @extend .body-bold;
    margin-bottom: 24px;
  }

  &__reminder {
    @extend .body;
    margin-bottom: 24px;
  }
}
