@use "styles/typography";
@use "styles/breakpoints";
@use "styles/vars";
@use "styles/colours";

.mygomex {
  @include vars.mainBackground;
  flex-grow: 1;
  &__container {
    flex-direction: column;

    &-top {
      display: flex;
      flex-direction: column;

      @media (min-width: breakpoints.$md) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  &__barcode-btn {
    width: 100%;
    margin-top: 16px;

    @media (min-width: breakpoints.$md) {
      margin-top: 0;
      width: 35%;
    }
  }

  &__section {
    margin-top: 32px;

    @media (min-width: breakpoints.$md) {
      flex: 0.5;

      &--left {
        margin-right: 8px;
      }

      &--right {
        margin-left: 8px;
      }

      &--two-col {
        display: flex;
        flex-direction: row;
        flex: 1;
      }
    }

    &--black-card {
      flex: 1;
      padding: 16px;
      display: flex;
      flex-direction: column;

      &__coffee {
          margin: 12px -16px 0px -16px;
          padding: 12px  16px 0px 16px;
          border-top: 1px solid colours.$midGrey;
        }

      @media (min-width: breakpoints.$md) {
        &__row {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        &__progress-bar {
          flex-grow: 1;
        }
        &__points {
          align-self: center;
          margin-right: 16px;
          width: 40%;
        }
      }
    }
  }
}
