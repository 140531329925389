@use "styles/colours";
@use "styles/typography";
@use "styles/breakpoints";


.user {
  &__change-password-desc {
    @extend .body;
    margin-bottom: 24px;
  }

  &__change-password-username {
    @extend .body;
    font-weight: bold;
    margin-bottom: 24px;
  }

  &__change-password-email {
    font-weight: bold;
  }

  &__wrapper-space-between {
    display: flex;
    justify-content: space-between;
  }
}

.user-profile-main {
  &__alert {
    padding-bottom: 16px;
  }
  &__section-title {
    @extend .title-two;
    margin-bottom: 24px;
  }
  &__wrapper{
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: flex-start;
    @media (max-width: breakpoints.$md) {
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      margin-top: 12px;
    }
  }
    
  &__threecol{
    width: 40%;
    max-width: 40%;
    flex-direction: row;
    margin-right: 15px;
    @media (max-width: breakpoints.$md) {
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      margin-right: 0px;
    }
  }

  &__onecol{
      width: 20%;
      max-width: 20%;
      flex-direction: row;
      @media (max-width: breakpoints.$md) {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
  
      }

  }

  &__threequartercol{
    width: 70%;
    max-width: 70%;
    flex-direction: row;
    margin-right: 16px;
    @media (max-width: breakpoints.$md) {
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      margin-right: 0px;
    }
  }

  &__onequartercol{
    width: 30%;
    max-width: 30%;
    flex-direction: row;

    @media (max-width: breakpoints.$md) {
      flex-direction: column;
      width: 100%;
      max-width: 100%;

    }
  }

  &__titlewrapper{
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
}

  &__label{
    width: 70%;
    max-width: 70%;
    flex-direction: row;
    margin-right: 16;
  }

  &__cancel {
    width: 30%;
    max-width: 30%;
    flex-direction: row;
    text-align: right;
    cursor: pointer;
  }

  &__changepasswordtitle{
    @extend .title-two;
  }

  &__rightcontent {
    align-content: flex-end;
    justify-content: right;
    text-align: right

    
  }
}

.change-password-wrapper{
  margin-top: 24px;
}

.space-between-rows {
  margin-bottom: 24px;
}

.input-label {
  @extend .body;
  margin-bottom: 8px;

  &:active {
    border-color: colours.$uiGrey;
  }

  &:hover {
    border-color: colours.$uiGrey;
  }

  &__bold {
    @extend .body-bold;
    margin-bottom: 8px;
    width: 100%;
  }

  &__body{
    @extend .body-bold;
    margin-bottom: 8px;
  }

  &__cancel {
    @extend .title-four;
    margin-bottom: 8px;
    font-size: 16px;
    margin-bottom: 8px;
    align-self: flex-start;
  }

  &__edit {
    align-self: flex-start
  }

  //Purpose in first name row
  &__cancelnamerow{
    @extend .title-four;
    margin-bottom: 8px;
    font-size: 16px;
    width: 100%;
    @media (max-width: breakpoints.$md) {
      display: none;
    }
  }

  //Cancel shows with firstname row in right side, but when mobile responsive hidden
  &__namecancel{
    @extend .title-four;
    font-size: 16px;
    margin-bottom: 8px;
    @media (min-width: breakpoints.$md) {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__right{
    text-align: right;
  }
}


.sticky-button {
  @extend .title-four;
  font-size: 16px;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 2px;

    @media (max-width: breakpoints.$md) {
      flex-direction: row;
      width: 100%;
      max-width: 100%;
    }


}