@use "colours";
@use "breakpoints";

/* Page controller swipe style overwritten  */
.swiper {
  width: 100%;
  height: 100%;
  overflow: visible;

  @media (min-width: breakpoints.$md) {
    overflow: hidden;
  }

  @media (min-width: breakpoints.$lg) {
    width: 100%;
  }
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination > .swiper-pagination-bullet {
  opacity: 1;
  border: colours.$white solid 1px;
  background-color: colours.$switchGrey;
}

.swiper-pagination {
  margin-top: 24px;
}
.swiper-pagination > .swiper-pagination-bullet-active {
  opacity: 1;
  border: colours.$white solid 1px;
  background-color: colours.$black;
}

.swiper-wrapper {
  padding-bottom: 16px;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -4px !important;
}
