@use "styles/colours";
@use "styles/vars";
@use "styles/breakpoints";


[data-testid="ProductDetailImage"] > img {
  display: flex;
  object-fit: contain;
  margin: 0 auto;
  max-height: 70vw;
  width: 100%;
  position: relative !important;

  @media (min-width: 568px) {
    max-height: 328px;
    min-width: 478px;
  }

  @media (min-width: breakpoints.$lg) {
    height: auto;
    max-height: 390px;
    max-width: 100%;
    min-width: initial;
    width: auto;
    justify-content: center;
  }
}

.product {
  &-modal-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    border-radius: 0px;
    width: 100%;
    -webkit-mask-image: -webkit-radial-gradient(
      white,
      black
    ); // fix for safari rounded corners

    @media (min-width: 478px) {
      width: auto;
      border-radius: 8px;
      margin: auto;
      max-height: 100vh;
      overflow: auto;
    }
  }
  &-meal-button {
    margin-top: 8px;
  }
  &__cart-btn-wrapper {
    position: sticky;
    min-height: 56px;
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    overflow: visible;
    max-width: 478px;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    background: white;
    @media (min-width: 568px) {
      border-radius: 0 0 8px 8px;
    }

    @media (min-width: 1024px) {
      // don't use sticky as it's relative to viewport, not to parent container 
      // and will cause button to hide when conatiner is shorter than viewport
      position: absolute; 
      max-width: 100%;
    }
  }

  &-wrapper {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin: auto;
    max-width: 478px;
    background-color: white;

    &-col {
      width: 100%;
    }

    @media (min-width: breakpoints.$lg) {
      width: 958px;
      flex-direction: row;
      max-width: 100%;
      height: calc(100% - 56px);
      max-height: calc(100vh - 56px);
      padding-bottom: 55px;

      &-col {
        width: 50%;
        overflow: auto;

        &:first-of-type {
          border-right: 1px solid lightGrey;
        }
        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    &--small {
      height: 100%;
      width: 100%;
      max-width: 478px;
      background-color: white;
      overflow: auto;
      position: relative;
      padding-bottom: 52px;

      .product__cart-btn-wrapper {
        position: fixed;
        background: white;
      }

      @media (min-width: 478px) {
        height: auto;
        padding-bottom: 0px;

        .product__cart-btn-wrapper {
          position: sticky;
          background: white;
        }
      }

      @media (min-width: breakpoints.$lg) {
        max-width: initial;
        width: 568px;
      }
    }
  }

  &-detail {
    background-color: #ffffff;
    position: relative;
    flex-grow: 1;
    overflow: hidden;
  }
  &-close-button-left {
    position: absolute;
    top: 16px;
    left: 16px;
  }
  &-close-button-right {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  &-text {
    margin-top: 8px;
    display: flex;
  }
  &-option-text {
    min-width: 75px;
  }
  &-option-customize-text {
    max-width: fit-content;
  }
  &-info {
    padding: 16px;

    .product-text {
      :not(:first-child) {
        border-left: 1px solid colours.$midGrey;
        padding-left: 8px;
        margin-left: 8px;
      }
    }
  }
  &-option-row {
    display: flex;
    flex-direction: row;
  }
  &-sticky-header-wrapper {
    width: 100%;
    position: fixed;
    max-width: 478px;
    z-index: 200;
    top: 0;
  }
  &-sticky-header-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  &-sticky-header-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  &-sticky-header-exit {
    opacity: 1;
  }
  &-sticky-header-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  &__customization {
    width: 100%;
    height: 100%;
    border-top: 1px solid lightGrey;
    background-color: colours.$lightestGrey;

    @media (min-width: 1024px) {
      overflow-y: auto;
      max-height: 768px;
      box-sizing: content-box;
    }
  }

  &__quantity {
    width: fit-content;
    margin: auto;
    margin-top: -24px;
    position: relative;
    z-index: 1;
  }

  &__allergen {
    margin-top: 4px;
  }

  &-nutritional-disclaimer-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__toggle {
    padding-bottom: 24px;
  }
}
