@use "styles/typography";
@use "styles/vars";

.order-status {
  flex-grow: 1;
  @include vars.mainBackground;
  &-map {
    width: 100%;
    min-height: 200px;
    margin-bottom: 16px;

    &__description {
      padding: 16px;
    }
    &__title {
      @extend .title-two;
      margin-bottom: 8px;
    }
    &__text {
      @extend .body-two;
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;

      &__more-info {
        flex: 0.8;
        margin-right: 8px;
      }

      &__direction {
        flex: 1;
      }
    }

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  &-gomex {
    margin-top: 16px;

    &--black-card {
      padding: 16px;
    }
  }
}
