@use "colours";
@use "typography";

.braintree-heading {
  @extend .title-two;
  text-transform: uppercase;
}

.gpay-button.black.short,
.gpay-button.black.plain {
  background-image: url("../assets/images/gpay-logo.svg") !important;
  background-color: colours.$white;
  border: 1px solid colours.$lightGrey;

  &:hover {
    background-color: colours.$starburst30;
    font-weight: bold;
  }

  &:active {
    background-color: colours.$starburst60;
    font-weight: bold;
  }
}
.braintree-option {
  background-color: colours.$white;
  border-color: colours.$lightGrey;
  color: colours.$darkGrey;
  padding: 0px;
  padding-left: 12px;

  &:hover {
    background-color: colours.$starburst30;
    font-weight: bold;
  }

  &:active {
    background-color: colours.$starburst60;
    font-weight: bold;
  }
}

.braintree-option .braintree-option__label {
  @extend .body-two;
  padding: 12px 10px;
  &:hover {
    font-weight: bold;
  }
}

.braintree-paypal {
  border-radius: 8px;
  border-color: colours.$lightGrey;
}

.braintree-googlePay {
  border-radius: 8px;
  border-color: colours.$lightGrey;
}

.braintree-sheet__text {
  @extend .body-two;
  color: colours.$darkGrey !important;
  font-size: 14px !important;
  margin-left: 12px !important;
}

.braintree-options-list {
  margin-top: 24px;
  background-color: colours.$white;
}

.braintree-form .braintree-form__label {
  @extend .body-two;
  color: colours.$darkGrey !important;
}

.braintree-large-button {
  background-color: colours.$white;
  text-align: left;

  &:hover {
    background-color: colours.$white;
  }
}

.braintree-card {
  border-radius: 8px;
  border-color: colours.$lightGrey;
}

.braintree-form__hosted-field {
  @extend .body-two;
  border-radius: 4px;
  border-color: colours.$lightGrey;
  padding: 10px 12px !important;
}

.braintree-sheet__header {
  border-bottom: 1px solid colours.$lightGrey;
}

.braintree-sheet__label {
  @extend .body-two;
  color: colours.$darkGrey;
  font-size: 14px !important;
}

.braintree-method {
  &:hover {
    background-color: colours.$starburst30;
  }
}
