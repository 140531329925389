@use "styles/colours";
@use "styles/typography";
@use "styles/breakpoints";

.main-login {
  &__header {
    display: block;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  &__subtitle {
    @extend .title-two;
    text-align: center;
    margin-bottom: 24px;
    justify-content: center;
  }

  &__or {
    @extend .title-three;
    text-align: center;
    margin-top: 16px;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__container {
    margin-top: 16px;
    margin-bottom: 16px;
    input::placeholder {
      color: colours.$uiGrey;
    }
  }

  &__social-container {
    background-color: colours.$yellow;
    padding: 16px;
    border-radius: 8px;
    margin-top: 16px;
  }

  &__titleThree {
    @extend .title-three;
    text-align: center;
    margin-bottom: 16px;
    justify-content: center;
  }

  &__error {
    margin-top: 16px;
  }

  .r-paddingHorizontal-1e081e0 {
    padding: 0;
  }

  .grey-line {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .forget-password {
    margin-top: 16px;
    align-items: flex-start;
  }

  &__section {
    margin-top: 24px;
  }

  &__bottom {
    margin-bottom: 8;
  }

  .title-four-regular {
    @extend .title-four;
    text-transform: none;
  }
  .easy-to-join {
    @extend .body;
    padding-top: 2px;
  }

  .flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .line {
    display: flex;
    align-self: center;
    background-color: colours.$lightGrey;
    height: 1px;
    flex: 1 1 0%;
  }
  .flex-text {
    padding-left: 16px;
    padding-right: 16px;
  }

  .join-gyg {
    &__title {
      @extend .title-four;
      margin-bottom: 4px;
      text-transform: none;
    }
    &__desc {
      @extend .body;
      flex-wrap: wrap;
    }
  }
  .otp-message {
    @extend .body;
    margin: 16px 0;
    text-align: center;
    & strong {
      @extend .body-bold;
    }
    & a {
      color: inherit;
    }
  }

  .pin-fields {
    display: flex;
    column-gap: 16px;
    margin: 40px 0;
    justify-content: center;
  }

  .pin-field {
    border: 1px solid #acacac;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    outline: none;
    text-align: center;
    padding: 0;
    color: #000;
    /* Title 1 */
    font-family: Sini;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;

    @media (max-width: breakpoints.$md) {
      width: 40px;
      height: 40px;
    }
  }
  .otp-mobile-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    img {
      display: block;
      width: 120px;
      height: 120px;
    }
  }
}
