.message-toast__container {
  // flex-grow: initial;
  left:0;
  right:0;

  margin-left: auto;
  margin-right: auto;

  position: fixed; 
  bottom:30px;
  z-index: 2;
}