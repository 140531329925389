@use "styles/breakpoints";

.rewards {
  &__card {
    width: 100%;
  }
  &__carousel-image {
    height: 155px;

    @media (min-width: breakpoints.$md) {
      height: 280px
    }
  }
}