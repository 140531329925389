@use "styles/colours";
@use "styles/typography";

.input-container {
  flex-direction: row;
  display: flex;
  border-radius: 4px;
  border: solid 2px colours.$lightGrey;
  padding: 0;
  min-height: 44px;

  &:hover {
    border-color: colours.$uiGrey;
  }

  &__focus {
    @extend .input-container;
    border-color: colours.$black;
    outline: none !important;
  }

  &__error {
    @extend .input-container;
    border-color: colours.$red;
    margin-bottom: 4px;

    &:hover {
      border-color: colours.$darkRed;
    }
  }
}

.input-container-with-button {
  border: 0px;
  border-bottom: solid 1px colours.$lightGrey;
  flex-direction: row;
  display: flex;
  border-radius: 4px;
  padding: 0;
  margin-bottom: 4px;
  min-height: 44px;

  &__buttons {
    flex-direction: row;
  }

  .button {
    display: flex;
    flex-direction: row;
  }
}

.logo {
  align-self: center;
  width: 32px;
  height: 32px;
  transition: none;

  &--error {
    filter: invert(10%) sepia(69%) saturate(6510%) hue-rotate(347deg)
      brightness(103%) contrast(112%);
    align-self: center;
    margin-right: 8px;
  }

  &--focus {
    width: 24px;
    height: 24px;
    align-self: center;
    margin-right: 12px;
    filter: invert(0.7);
  }

  &--eye {
    width: 24px;
    height: 24px;
    align-self: center;
    margin-right: 8px;
    margin-top: -4px;
    filter: invert(0.4);
  }

  &--pencil {
    width: 24px;
    height: 24px;
    align-self: center;
    margin-right: 4px;
    margin-top: -4px;
    filter: invert(0.3);

    &-disabled {
      width: 24px;
      height: 24px;
      align-self: center;
      margin-right: 4px;
      margin-top: -4px;
      filter: invert(0.7);
    }
  }

  &--invisible {
    opacity: 0;

    align-self: center;
    margin-right: 8px;
  }
}

.input {
  width: 100%;
  height: inherit;
  border: 0;
  @extend .body-bold;
  font-size: 14px;
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: colours.$white;
  color: colours.$darkGrey;
  &:focus {
    border-color: transparent;
    outline: none !important;
  }
  &__zeroleftpadding {
    padding-left: 0px;
  }

  &--empty {
    @extend .caption-two;
    font-size: 14px;
  }

  &__prefix {
    @extend .body-bold;
    display: inline-flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    padding-left: 8px;

    + input {
      padding-left: 4px;
    }
  }

  &__label {
    @extend .body;
    margin-bottom: 8px;

    &--error {
      color: colours.$red;
    }
  }

  /* Safari 7.1+ */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      overflow: hidden;
    }
  }
}

.error-message {
  margin-bottom: 16px;
  @extend .caption-one;
  color: colours.$red;

  &__invisible {
    margin-bottom: 16px;
    @extend .caption-one;
    color: transparent;
  }
}

.input-label {
  @extend .body;

  &__bold {
    @extend .body-bold;
  }

  &__error {
    @extend .body;
    color: colours.$red;
  }
}

.button-label {
  @extend .title-four;
  font-size: 16px;
  margin-top: 10px;
  color: colours.$darkGrey;

  &-disabled {
    @extend .title-four;
    font-size: 16px;
    margin-top: 10px;
    color: colours.$uiGrey;
  }
}
