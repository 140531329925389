@use "styles/colours";
@use "styles/typography";
@use "styles/breakpoints";

.guest-detail {
  &__col {
    padding-bottom: 32px;

    @media (min-width: breakpoints.$md) {
      padding-bottom: 0;
    }
  }

  .heading {
    @extend .title-two;
  }

  .subheading {
    @extend .caption-one;
    margin-top: 13px;
  }

  .title-container {
    margin-bottom: 24px;
  }

  .login-container {
    margin-bottom: 32px;

    &__paragraph {
      @extend .body;
      margin-top: 8px;
      margin-bottom: 24px;
    }
  }

  .grey-line {
    margin-top: 24px;

    &__mobile {
      @media only screen and (max-width: breakpoints.$md) {
        background-color: colours.$lightGrey;
        height: 1px;
        margin-bottom: 16px;
      }
    }
  }

  .receipt-container {
    border-radius: 8px;
    background-color: colours.$white;
    padding: 24px 0;
    padding-bottom: 0;

    @media only screen and (max-width: breakpoints.$md) {
      margin-top: 0;
    }

    &__title {
      @extend .title-two;
      padding: 0 16px;
    }

    &__label--total {
      @extend .title-three;
    }

    &__label--price {
      @extend .title-three;
      text-align: right;
    }

    &__bottom {
      margin-top: -2px;
      background-color: colours.$lightestGrey;
      padding: 16px 24px 16px;
      border-top: solid 1px colours.$lightGrey;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__surcharges{
      background-color: colours.$lightestGrey;
      padding: 0 24px 16px 24px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .select-pay-button {
    margin-top: 24px;
  }
}
