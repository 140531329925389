@use "styles/colours";

.alertMessage {
  &-modal-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    height: 248px;
    width: 330px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    background-color: white;
    -webkit-mask-image: -webkit-radial-gradient(
      white,
      black
    ); // fix for safari rounded corners

    @media (min-width: 568px) {
      height: 258px;
      width: 342px; //Removing the padding
    }
  }
  &-detail {
    background-color: #ffffff;
    position: relative;
    flex-grow: 1;
  }
  &-close-button-right {
    position: absolute;
    top: 16px;
    right: 0px;
  }
}
