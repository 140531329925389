@use "styles/breakpoints";

.notifications {
  &__container {
    flex-direction: column;

    @media (max-width: breakpoints.$md) {
      margin-top: 0px;
    }
  }
}