@use "styles/colours";
@use "styles/typography";
@use "styles/breakpoints";
@use "styles/vars";
@use "styles/swiper.scss";

.dashboard {
  @include vars.mainBackground;
  flex-grow: 1;

  &__container.main-wrapper {
    flex-direction: column;
  }

  &__section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    @media (min-width: breakpoints.$md) {
      padding: 16px 0;
    }

    &__refer {
      display: flex;
      width: 50%;

      @media (max-width: breakpoints.$md) {
        width: 100%;
      }
    }
  }

  &__subsection {
    padding-bottom: 16px;
  }

  &__gomex-black-card {
    display: flex;
    width: 100%;
    background-color: colours.$darkGrey;
    padding: 16px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;

    &:hover {
      background-color: colours.$blackOpacity;
    }

    &__title {
      @extend .title-two;
      color: colours.$white;
      margin-bottom: 10px;
    }

    &__animation {
      width: 136px;
      height: 56px;
      position: absolute;
      right: 0;
      top: 0;
    }

    &__points {
      width: 100%;

      @media (min-width: breakpoints.$md) {
        width: 40%;
      }
    }

    &__icon {
      width: 32px;
      height: 32px;
      filter: invert(80%) sepia(70%) saturate(1519%) hue-rotate(350deg)
        brightness(103%) contrast(108%);
      position: absolute;
      right: 16px;
      top: 50%;

      @media (max-width: breakpoints.$md) {
        display: none;
      }
    }
  }

  &__coffee-loyalty-card {
    display: flex;
    width: 100%;
    background-color: colours.$darkGrey;
    padding: 24px 16px;
    position: relative;
    align-items: center;
    min-height: 89px;
    border-top: 1px solid colours.$midGrey;

    &:hover {
      background-color: colours.$blackOpacity;
    }
  }

  &__cta {
    &-text {
      @extend .body-two;
      margin: 4px 0 8px;

      @media (min-width: breakpoints.$md) {
        margin-bottom: 0;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      padding: 16px;

      @media (min-width: breakpoints.$md) {
        flex-direction: row;
      }
    }

    &-copy {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
    }

    &-btn {
      min-width: 160px;
    }
  }

  &__favourite {
    .swiper {
      height: auto;
    }

    .swiper-wrapper {
      margin-bottom: 4px;
      height: auto;

      @media (min-width: breakpoints.$md) {
        margin-bottom: 8px;
      }
    }

    &-title {
      @extend .title-one;
      margin-bottom: 16px;
      text-transform: capitalize;
    }

    &-wrapper {
      margin-top: 16px;
      padding-right: 16px;
      max-width: 100%;
      overflow: visible;

      &__section {
        width: 100%;
      }
    }
  }

  &__orders {
    position: relative;
    width: 100%;

    .swiper {
      height: auto;
    }

    .swiper-wrapper {
      margin-bottom: 4px;
      height: auto;

      @media (min-width: breakpoints.$md) {
        margin-bottom: 8px;
      }
    }

    &-title {
      @extend .title-one;
      text-transform: capitalize;
      margin-bottom: 16px;

      &--wrapper {
        position: absolute;
        top: -36px;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &-button {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-text {
        @extend .title-three;
      }

      &-icon {
        width: 24px;
        height: 24px;
      }
    }

    &-card {
      display: flex;
      flex: 1;
      width: 100%;
      margin-right: 4px;
      margin-left: 4px;
      margin-bottom: 4px;
    }
  }

  &__rewards {
    width: 100%;

    .swiper {
      height: auto;
    }

    .swiper-wrapper {
      margin-bottom: 4px;
      height: auto;

      @media (min-width: breakpoints.$md) {
        margin-bottom: 8px;
      }
    }

    &-title {
      @extend .title-one;
      text-transform: capitalize;
      margin-bottom: 16px;

      &--wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &-card {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 4px;
      margin-left: 4px;
      margin-bottom: 4px;
    }

    &-date {
      text-transform: capitalize;
      margin-top: 8px;
      &--wrapper {
        display: flex;
        flex: 1;
        flex-direction: row-reverse;
      }

      @extend .caption-one;
    }
  }

  &__promotions {
    width: 100%;

    .swiper {
      height: auto;
    }

    .swiper-wrapper {
      margin-bottom: 4px;
      height: auto;

      @media (min-width: breakpoints.$md) {
        margin-bottom: 8px;
      }
    }

    &-title {
      @extend .title-one;
      text-transform: capitalize;
      margin-bottom: 16px;

      &--wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &-card {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-bottom: 24px;
    }
  }

  // was breaking z indexes on my modal, should never have nested styles in CSS
  // best practice is to only have one level, this is why i like to use styled-component
  // as it forces this
  // .css-view-1dbjc4n {
  //   z-index: auto;
  // }
}
