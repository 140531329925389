@use "styles/colours";
@use "styles/breakpoints";
@use "styles/vars";
@use "styles/typography";

.barcode {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;

  &__container {
    padding: 24px;
    background-color: colours.$white;
    border-radius: 8px;
  }

  &__canvas {
    width: 324px;
  }

  &__number {
    @extend .title-two;
    color: colours.$darkGrey;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 8px;
  }

  &__card {
    background-image: radial-gradient(circle at 9% 12%, colours.$black, #262626 80%);
    border-radius: 8px;
    padding: 24px;
    margin-top: -8px;
    width: 324px;

    &-header {
      width: 208px;
      height: 60px;
      margin-left: -56px;
      margin-top: -8px;
      margin-bottom: 8px;
    }

    &-text {
      &__title {
        @extend .title-three;
        font-size: 16px;
        color: colours.$midGrey;
        margin-bottom: 4px;
      }

      &__value {
        @extend .title-two;
        color: colours.$white;

        &-name {
          @extend .title-two;
          color: colours.$white;
          margin-bottom: 12px;
        }
      }
    }

    &__points {
      display: flex;
      flex-direction: row;

      &__section {
        flex-direction: column;
        margin-right: 42px;
      }
    }

    &__logo {
      height: 19px;
      width: 44px;
      align-self: flex-end;
      margin-left: auto;
    }
  }

  &__close {
    &-icon {
      width: 42px;
      height: 42px;
    }

    &-button {
      margin-top: 24px;
      background-color: colours.$lightGrey;
      width: 54px;
      height: 54px;
      border-radius: 8px;
    }
  }
}
