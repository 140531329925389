@use "styles/typography";
@use "styles/colours";
@use "styles/vars";
@use "styles/breakpoints";

.location {
  border: solid 2px colours.$yellow;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding:  8px 8px 8px 8px;

  @media (min-width: breakpoints.$md) {
    flex-direction: row;
  }

  &__title {
    flex-direction: row;
    display: flex;
    align-self: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    margin-bottom: 8px;

    @media (min-width: breakpoints.$md) {
      margin-bottom: 0px;
    }

    &--no-restaurants {
      width: 60%;      
    }
  }

  &__title-text {
    @extend .headline;
    margin-left: 4px;
  }

  &__body {
    padding-right: 16px;
    @extend .body-two;
    align-self: flex-start;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__content {
    display: flex;
    width: 100%;

    @media (min-width: breakpoints.$lg) {
      flex-direction: row;
    }
  }

  &__button {
    width: calc(100%);
    align-self: center;

    &--no-store {
      @media (min-width: breakpoints.$md) {
        width: 50%;
      }

      @media (min-width: breakpoints.$lg) {
        width: auto;
        margin-right: 16px;
        align-items: center;
      }
    }

    &--location-perm {
      @media (min-width: breakpoints.$md) {
        width: calc(100% - 14px);
        display: flex;
        flex-direction: column;

        &__element {
          width: calc(70% - 8px);
          align-self: flex-end;
        }
      }

      @media (min-width: breakpoints.$lg) {
        width: calc(100% - 18px);
        margin-right: 4px;
      }

      &--no-margin {

        @media (min-width: breakpoints.$md) {
          margin-left: 16px;
        }

        @media (min-width: breakpoints.$lg) {
          margin-left: 8px;
        }
      }
    }
  }
}
