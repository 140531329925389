@use "styles/colours";
@use "styles/typography";
@use "styles/breakpoints";
@use "styles/vars";

.order {
  &__ways-to-pay {
    background-color: colours.$lightestGrey;
    padding-top: 16px;
    border-color: colours.$lightGrey;
    border-top-width: 1px;
    border-top-style: solid;

    @media (min-width: breakpoints.$md) {
      order: initial;
    }

    &-title {
      padding: 0 16px;
      margin-bottom: 16px;
    }

    &-options {
      padding: 0 16px 16px;

      > div:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  &__container {
    &.main-wrapper {
      padding-right: 0;
      padding-left: 0;
    }

    .main-wrapper__col {
      display: flex;
      flex-direction: column;
    }

    @media (min-width: breakpoints.$md) {
      &.main-wrapper {
        padding-right: 24px;
        padding-left: 24px;
      }
    }

    @media (min-width: breakpoints.$lg) {
      &.main-wrapper {
        /* padding-right: 232px;
        padding-left: 232px; */
        padding-left: 0;
        padding-right: 0;
        max-width: breakpoints.$md;
      }
    }
  }

  &__col {
    padding: 0 16px;
    width: auto;

    &--bg {
      background-image: url("../../../../../packages/common/components/assets/images/main_bg.jpg");
      background-size: cover;
      padding: 16px;
    }

    @media (min-width: breakpoints.$md) {
      padding: 0;
      &--bg {
        padding-top: 16px;
        background-image: none;
      }
    }
  }

  &__error-container {
    margin-bottom: 8px;
  }

  &__section {
    padding-bottom: 16px;
  }

  &__payment-wrapper {
    background-color: colours.$lightestGrey;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: colours.$lightGrey;
  }

  &__gomex {
    margin-bottom: 16px;
    padding: 0 16px;

    @media (min-width: breakpoints.$md) {
      order: initial;
      padding: 0;
    }
  }

  &__space {
    height: 16px;
  }
}

.total {
  &__container {
    background-color: colours.$lightestGrey;
    padding: 24px 16px;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  &__label {
    @extend .title-one;
  }
}

.cutlery {
  &__container {
    padding: 16px;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
  &__label {
    @extend .title-three;
  }
  &__checkbox {
    border: none;
    padding: 0;
  }
}

.sticky-button {
  @media only screen and (max-width: breakpoints.$md) {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    left: 0;
    background-color: colours.$white;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;
  }
}

.save-payment {
  &__container {
    padding: 24px 16px;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
    border-top-width: 1px;
    border-style: solid;
    border-color: colours.$lightGrey;
  }

  &__label {
    @extend .title-three;
  }
}

.upsell-cart {
  margin-top: 16px;
  margin-bottom: 16px;

  &__title {
    @extend .title-three;
    margin-bottom: 16px;
  }

  .list-group {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    min-height: 70px;
    margin-bottom: 8px;
    border-radius: 8px;

    .menu__product {
      &-prompt {
        display: none;
      }
  
      &-ingridients {
        display: none;
      }
  
      &-container {
        flex-direction: row;
        justify-content: flex-start;
      }
  
      &-title {
        margin-bottom: 4px ;
        margin-top: 4px;
      }
  
      &-heading {
        flex-direction: column ;
  
        > :not(:only-child):first-child {
          margin-bottom: 8px;
          align-self: start;
        }
        
      }

      &-imageWrapper {
        align-self: center;
        margin: 0 auto;
        position: relative;
    }
  
      &-image {
        height: 88px;
        width: 110px;
      }
  
      &-information {
        padding: 24px 24px 16px 0;
      }
    }
    
  }
}

.receipt-layout {
  background-color: colours.$lightestGrey;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: colours.$lightGrey;
}

.order-receipt {
  @extend .receipt-layout;

  &__section {
    padding-top: 24px;
    @extend .receipt-layout;
    border-bottom-style: dashed;
    border-top-width: 0;
  }

  &__row {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
    &--surcharges{
      display: flex;
      justify-content: center;
      padding-bottom: 24px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__label {
    @extend .title-two;
  }

  &__total {
    background-color: colours.$lightestGrey;
    padding: 24px 16px;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;

    &-label {
      @extend .title-one;
    }

    &:nth-of-type(2) {
      border-top: 1px colours.$lightGrey;
      border-style: solid;
    }
  }
}
.cta {
    &-wrapper {
      display: flex;
      flex-direction: column;
      padding: 16px;
    }
    &-text {
      @extend .body-two;
      margin-top: 8px;
      margin-bottom: 16px;
    }
}

.pickup-label {
  margin-top: 8px;
}
.pickup-title {
  margin-bottom: 16px;
}

input[type="checkbox"]:checked:disabled {
  opacity: 0; //it fixes style issue on mobile browsers when toggle is checked and disabled during loading process
}
