@use "styles/typography";
@use "styles/colours";
@use "styles/vars";
@use "styles/breakpoints";

.header-setup-order {
  border: solid 2px colours.$yellow;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;

  &__button {
    cursor: pointer;
    width: 100%;
    &--disabled {
      cursor: default;
      pointer-events: none;
    }
  }

  &__container {
    padding-top: 8px;
    padding-bottom: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (min-width: breakpoints.$md) {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 8px;
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    @media (min-width: breakpoints.$lg) {
      justify-content: space-between;
    }
  }

  &__edit-container {
    align-self: center;
    padding-right: 16px;

    @media (min-width: breakpoints.$md) {
      box-sizing: border-box;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__text {
    padding-left: 16px;
    align-items: center;
    flex-direction: row;
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &--name {
      @extend .body-bold;
      margin-left: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--wrap {
      @extend .body-bold;
      margin-left: 4px;
    }

    &--distance {
      @extend .caption-one;
      margin-left: 8px;
    }
  }

  &--closed {
    margin-left: 16px;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
  &__icon_order_type {
    width: 20px;
    height: auto;
    padding: 0 2.5px;
    @media (min-width: breakpoints.$md) {
      padding: 0 5px 0 0;
    }
  }

  &__edit {
    @extend .title-three;
    font-size: 16px;
    letter-spacing: 0.5px;
    margin-left: 4px;
  }
}
